<script>
import { mapGetters } from 'vuex';
import { useAlert } from 'dashboard/composables';
import NewWebhook from './NewWebHook.vue';
import EditWebhook from './EditWebHook.vue';
import WebhookRow from './WebhookRow.vue';
import BaseSettingsHeader from '../../components/BaseSettingsHeader.vue';
import SettingsLayout from '../../SettingsLayout.vue';

export default {
  components: {
    SettingsLayout,
    BaseSettingsHeader,
    NewWebhook,
    EditWebhook,
    WebhookRow,
  },
  data() {
    return {
      loading: {},
      showAddPopup: false,
      showEditPopup: false,
      showDeleteConfirmationPopup: false,
      selectedWebHook: {},
    };
  },
  computed: {
    ...mapGetters({
      records: 'webhooks/getWebhooks',
      uiFlags: 'webhooks/getUIFlags',
    }),
    integration() {
      return this.$store.getters['integrations/getIntegration']('webhook');
    },
    tableHeaders() {
      return [
        this.$t(
          'INTEGRATION_SETTINGS.WEBHOOK.LIST.TABLE_HEADER.WEBHOOK_ENDPOINT'
        ),
        this.$t('INTEGRATION_SETTINGS.WEBHOOK.LIST.TABLE_HEADER.ACTIONS'),
      ];
    },
  },
  mounted() {
    this.$store.dispatch('webhooks/get');
  },
  methods: {
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selectedWebHook = response;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    openEditPopup(webhook) {
      this.showEditPopup = true;
      this.selectedWebHook = webhook;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },
    confirmDeletion() {
      this.loading[this.selectedWebHook.id] = true;
      this.closeDeletePopup();
      this.deleteWebhook(this.selectedWebHook.id);
    },
    async deleteWebhook(id) {
      try {
        await this.$store.dispatch('webhooks/delete', id);
        useAlert(
          this.$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.API.SUCCESS_MESSAGE')
        );
      } catch (error) {
        useAlert(
          this.$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.API.ERROR_MESSAGE')
        );
      }
    },
  },
};
</script>

<template>
  <SettingsLayout :is-loading="uiFlags.fetchingList" :loading-message="$t('INTEGRATION_SETTINGS.WEBHOOK.LOADING')"
    :no-records-message="$t('INTEGRATION_SETTINGS.WEBHOOK.LIST.404')" :no-records-found="!records.length">
    <template #header>
      <BaseSettingsHeader v-if="integration.name" :title="integration.name" :description="integration.description"
        feature-name="webhook" :back-button-label="$t('INTEGRATION_SETTINGS.HEADER')">
        <template #actions>
          <woot-button class="button nice rounded-md" icon="add-circle" @click="openAddPopup">
            {{ $t('INTEGRATION_SETTINGS.WEBHOOK.HEADER_BTN_TXT') }}
          </woot-button>
        </template>
      </BaseSettingsHeader>
    </template>
    <template #body>
      <table class="min-w-full divide-y divide-slate-75 dark:divide-slate-700">
        <thead>
          <th v-for="thHeader in tableHeaders" :key="thHeader"
            class="py-4 pr-4 text-left font-semibold text-slate-700 dark:text-slate-300 last:text-right last:pr-4">
            {{ thHeader }}
          </th>
        </thead>
        <tbody class="divide-y divide-slate-25 dark:divide-slate-800 flex-1 text-slate-700 dark:text-slate-100">
          <WebhookRow v-for="(webHookItem, index) in records" :key="webHookItem.id" :index="index"
            :webhook="webHookItem" @edit="openEditPopup" @delete="openDeletePopup" />
        </tbody>
      </table>
    </template>
    <woot-modal v-model:show="showAddPopup" :on-close="hideAddPopup">
      <NewWebhook v-if="showAddPopup" :on-close="hideAddPopup" />
    </woot-modal>

    <woot-modal v-model:show="showEditPopup" :on-close="hideEditPopup">
      <EditWebhook v-if="showEditPopup" :id="selectedWebHook.id" :value="selectedWebHook" :on-close="hideEditPopup" />
    </woot-modal>
    <woot-delete-modal v-model:show="showDeleteConfirmationPopup" :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion" :title="$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.TITLE')" :message="$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.MESSAGE', {
        webhookURL: selectedWebHook.url,
      })
        " :confirm-text="$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.YES')"
      :reject-text="$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.NO')" />
  </SettingsLayout>
</template>
