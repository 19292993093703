export default [
  {
    id: 1,
    title: "Como obter um certificado SSL para o domínio personalizado do seu Centro de Ajuda",
    status: 'rascunho',
    updatedAt: 1729205669,
    author: { availableName: 'Michael' },
    category: {
      slug: 'configuracao',
      icon: '📦',
      name: 'Configuração & Setup',
    },
    views: 3400,
  },
  {
    id: 2,
    title: 'Configurando seu primeiro portal do Centro de Ajuda',
    status: 'publicado',
    updatedAt: 1729205669,
    author: { availableName: 'John' },
    category: { slug: 'integração', icon: '🧑‍🍳', name: 'Integração' },
    views: 400,
  },
  {
    id: 3,
    title: 'Melhores práticas para organizar o conteúdo do seu Centro de Ajuda',
    status: 'arquivado',
    updatedAt: 1729205669,
    author: { availableName: 'Fernando' },
    category: { slug: 'melhores-praticas', icon: '⛺️', name: 'Melhores Práticas' },
    views: 400,
  },
  {
    id: 4,
    title: 'Personalizando a aparência do seu Centro de Ajuda',
    status: 'rascunho',
    updatedAt: 1729205669,
    author: { availableName: 'Jane' },
    category: { slug: 'design', icon: '🎨', name: 'Design' },
    views: 400,
  },
  {
    id: 5,
    title: 'Integrando seu Centro de Ajuda com ferramentas de terceiros',
    status: 'publicado',
    updatedAt: 1729205669,
    author: { availableName: 'Sarah' },
    category: {
      slug: 'integracoes',
      icon: '🔗',
      name: 'Integrações',
    },
    views: 2800,
  },
  {
    id: 6,
    title: 'Gerenciando permissões de usuários no seu Centro de Ajuda',
    status: 'rascunho',
    updatedAt: 1729205669,
    author: { availableName: 'Alex' },
    category: {
      slug: 'administracao',
      icon: '🔐',
      name: 'Administração',
    },
    views: 1200,
  },
  {
    id: 7,
    title: 'Criando e gerenciando seções de FAQ',
    status: 'publicado',
    updatedAt: 1729205669,
    author: { availableName: 'Emily' },
    category: {
      slug: 'gerenciamento-de-conteudo',
      icon: '📝',
      name: 'Gerenciamento de Conteúdo',
    },
    views: 5600,
  },
  {
    id: 8,
    title: 'Implementando funcionalidade de busca no seu Centro de Ajuda',
    status: 'arquivado',
    updatedAt: 1729205669,
    author: { availableName: 'David' },
    category: {
      slug: 'funcionalidades',
      icon: '🔍',
      name: 'Funcionalidades',
    },
    views: 1800,
  },
  {
    id: 9,
    title: 'Analisando métricas de uso do Centro de Ajuda',
    status: 'publicado',
    updatedAt: 1729205669,
    author: { availableName: 'Rachel' },
    category: {
      slug: 'analitica',
      icon: '📊',
      name: 'Análise',
    },
    views: 3200,
  },
  {
    id: 10,
    title: 'Configurando suporte multilíngue no seu Centro de Ajuda',
    status: 'rascunho',
    updatedAt: 1729205669,
    author: { availableName: 'Carlos' },
    category: {
      slug: 'localizacao',
      icon: '🌍',
      name: 'Localização',
    },
    views: 900,
  },
  {
    id: 11,
    title: 'Criando tutoriais interativos para seus produtos',
    status: 'publicado',
    updatedAt: 1729205669,
    author: { availableName: 'Olivia' },
    category: {
      slug: 'educacao',
      icon: '🎓',
      name: 'Educação',
    },
    views: 4100,
  },
  {
    id: 12,
    title: 'Implementando um sistema de feedback no seu Centro de Ajuda',
    status: 'rascunho',
    updatedAt: 1729205669,
    author: { availableName: 'Nathan' },
    category: {
      slug: 'engajamento-do-usuario',
      icon: '💬',
      name: 'Engajamento do Usuário',
    },
    views: 750,
  },
  {
    id: 13,
    title: 'Otimizando o conteúdo do Centro de Ajuda para SEO',
    status: 'publicado',
    updatedAt: 1729205669,
    author: { availableName: 'Sophia' },
    category: {
      slug: 'seo',
      icon: '🚀',
      name: 'SEO',
    },
    views: 2900,
  },
  {
    id: 14,
    title: 'Criando uma base de conhecimento para equipes internas',
    status: 'arquivado',
    updatedAt: 1729205669,
    author: { availableName: 'Daniel' },
    category: {
      slug: 'recursos-internos',
      icon: '🏢',
      name: 'Recursos Internos',
    },
    views: 1500,
  },
];
