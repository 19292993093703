<script setup>
import { useAlert } from 'dashboard/composables';
import MacrosTableRow from './MacrosTableRow.vue';
import BaseSettingsHeader from '../components/BaseSettingsHeader.vue';
import SettingsLayout from '../SettingsLayout.vue';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStoreGetters, useStore } from 'dashboard/composables/store';

const getters = useStoreGetters();
const store = useStore();
const { t } = useI18n();

const showDeleteConfirmationPopup = ref(false);
const selectedMacro = ref({});

const records = computed(() => getters['macros/getMacros'].value);
const uiFlags = computed(() => getters['macros/getUIFlags'].value);

const deleteMessage = computed(() => ` ${selectedMacro.value.name}?`);

onMounted(() => {
  store.dispatch('macros/get');
});

const deleteMacro = async id => {
  try {
    await store.dispatch('macros/delete', id);
    useAlert(t('MACROS.DELETE.API.SUCCESS_MESSAGE'));
  } catch (error) {
    useAlert(t('MACROS.DELETE.API.ERROR_MESSAGE'));
  }
};

const openDeletePopup = response => {
  showDeleteConfirmationPopup.value = true;
  selectedMacro.value = response;
};

const closeDeletePopup = () => {
  showDeleteConfirmationPopup.value = false;
};

const confirmDeletion = () => {
  closeDeletePopup();
  deleteMacro(selectedMacro.value.id);
};

const tableHeaders = computed(() => {
  return [
    t('MACROS.LIST.TABLE_HEADER.NAME'),
    t('MACROS.LIST.TABLE_HEADER.CREATED BY'),
    t('MACROS.LIST.TABLE_HEADER.LAST_UPDATED_BY'),
    t('MACROS.LIST.TABLE_HEADER.VISIBILITY'),
  ];
});
</script>

<template>
  <SettingsLayout :no-records-message="$t('MACROS.LIST.404')" :no-records-found="!records.length"
    :is-loading="uiFlags.isFetching" :loading-message="$t('MACROS.LOADING')" feature-name="macros">
    <template #header>
      <BaseSettingsHeader :title="$t('MACROS.HEADER')" :description="$t('MACROS.DESCRIPTION')" feature-name="macros">
        <template #actions>
          <router-link :to="{ name: 'macros_new' }" class="button rounded-md primary">
            <fluent-icon icon="add-circle" />
            <span class="button__content">
              {{ $t('MACROS.HEADER_BTN_TXT') }}
            </span>
          </router-link>
        </template>
      </BaseSettingsHeader>
    </template>
    <template #body>
      <table class="min-w-full divide-y divide-slate-75 dark:divide-slate-700">
        <thead>
          <th v-for="thHeader in tableHeaders" :key="thHeader"
            class="py-4 ltr:pr-4 rtl:pl-4 text-left font-semibold text-slate-700 dark:text-slate-300">
            {{ thHeader }}
          </th>
        </thead>
        <tbody class="divide-y divide-slate-50 dark:divide-slate-800 text-slate-700 dark:text-slate-300">
          <MacrosTableRow v-for="(macro, index) in records" :key="index" :macro="macro"
            @delete="openDeletePopup(macro)" />
        </tbody>
      </table>
      <woot-delete-modal v-model:show="showDeleteConfirmationPopup" :on-close="closeDeletePopup"
        :on-confirm="confirmDeletion" :title="$t('LABEL_MGMT.DELETE.CONFIRM.TITLE')"
        :message="$t('MACROS.DELETE.CONFIRM.MESSAGE')" :message-value="deleteMessage"
        :confirm-text="$t('MACROS.DELETE.CONFIRM.YES')" :reject-text="$t('MACROS.DELETE.CONFIRM.NO')" />
    </template>
  </SettingsLayout>
</template>
